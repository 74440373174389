.accordion-header[data-state="open"] {
  padding: 2px;
  background: var(--design-color-surface-action-menu-active);
}

.accordion-header[data-state="closed"] {
  padding: 2px;
  background: white;
}

.accordion-header:hover {
  padding: 2px;
  background: var(--design-color-surface-action-menu-hover);
}
