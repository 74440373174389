.nt-border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px;
}

.nt-focus:focus {
  outline-offset: 0px;
  z-index: 1;
}

.selected {
  font-weight: 700;
  background-color: #E6EEF0 !important; // --Colors-Surface-Action-Menu-active
}
