
.text-box {

  $primary-blue-light: #E6F3FF; // this should be temporary, since it belongs to the old color palette

  padding: var(--design-spacing-lg);
  box-sizing: border-box;
  font-size: var(--design-font-md);
  margin-bottom: var(--design-spacing-md);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;

  /* remove padding from last element to avoid alignment issues */
  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    padding: var(--design-spacing-md);
  }

  &.small {
    max-width: 30rem;
    width: 100%;
  }
  &.medium {
    max-width: 40rem;
    width: 100%;
  }
  &.large {
    width: 100%;
    max-width: 60rem;
  }
  &.half {
    width: 50%;
    @media(max-width: 40rem) {
      width: 100%;
    }
  }

  &.noPadding {
    padding: 0;
  }

  // color themes
  &.purple {
    background-color: var(--hdir-color-purple-dark);
    color: var(--hdir-color-purple-lightest);
  }

  &.pink {
    background-color: var(--hdir-color-purple-lightest);
    color: var(--hdir-color-purple-dark);
  }

  &.blue-light {
    background-color: $primary-blue-light;
    color: var(--hdir-color-primary-900);
  }

  &.blue-dark {
    background-color: var(--hdir-color-primary-900);
    color: $primary-blue-light;
  }

  // rounded borders for box. Can have multiple set at the same time
  &.tl {
    border-top-left-radius: var(--design-border-radius-lg);
  }

  &.tr {
    border-top-right-radius: var(--design-border-radius-lg);
  }

  &.bl {
    border-bottom-left-radius: var(--design-border-radius-lg);
  }

  &.br {
    border-bottom-right-radius: var(--design-border-radius-lg);
  }

  .heading {
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0rem 1rem;
    font-size: var(--design-font-lg);
    font-weight: bold;
  }

  .icon {
    max-width: 2rem;
    max-height: 2rem;

    &.pink {
      color: var(--hdir-color-purple-dark);
    }
    &.purple {
      color: var(--hdir-color-purple-lightest);
    }
    &.blue-light {
      color: var(--hdir-color-primary-900);
    }

    &.blue-dark {
      color: $primary-blue-light;
    }
  }
}
