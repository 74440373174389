.container {
  position: relative;

  * {
    box-sizing: border-box;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: var(--design-border-radius-sm);
}

.position {
  position: absolute;
  padding: var(--design-spacing-lg);
  inset: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  flex-basis: 50%;

  // turn off on smaller screens to avoid specificity issues
  @media (min-width: 769px) {
    & {
      flex-basis: 100%;
    }

    &.tl {
      // reduntant styling, but its nice to be explicit
      justify-content: start;
      align-items: start;
    }

    &.tr {
      justify-content: start;
      align-items: end;
    }

    &.bl {
      justify-content: end;
    }

    &.br {
      justify-content: end;
      align-items: end;
    }
    &.tc {
      align-items: center;
    }

    &.rc {
      justify-content: center;
      align-items: end;
    }

    &.bc {
      justify-content: end;
      align-items: center;
    }

    &.lc {
      justify-content: center;
    }
    &.c {
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;

    position: relative;
    inset: auto;
    bottom: 4rem;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: -4rem;
  }
}
